import { hasProperty } from './common';
import { getConvertDataProduct } from './product';

export const CALCULATORS_SPECIALIST = [
  {
    link        : '/calculator/',
    name        : 'Калькулятор синдрома сухого глаза',
    description : 'Тест ССГ на&nbsp;основе опросника OSDI (Ocular Surface Disease Index)'
  },
  {
    link        : '/pro/calculator-lenses/',
    name        : 'Калькулятор расчета контактных линз',
    description : 'Расчет оптической силы контактных линз Alcon'
  },
  {
    link        : '/kalkulyator-diskomforta/',
    name        : 'Калькулятор дискомфорта в&nbsp;контактных линзах',
    description : 'Тест на&nbsp;основе опросника CLDEQ-8 (Contact Lens Dry Eye Questionnaire-8)'
  }
];

// Для онлайн-тренингов и статей
export const getConvertMaterial = (category, userData) => {
  const result = {
    list   : [],
    themes : []
  };

  if (category.list && category.list.length) {
    result.list = category.list.map((element) => {
      // Флаг Пройдено
      const isPassed = Boolean(
        userData
        && element.id
        && userData[element.id]
        && !userData[element.id].is_new
        && element.total_stars
        && element.total_stars > 0
        && userData[element.id].stars !== null
      );

      return {
        id            : element.id,
        link          : element.link,
        title         : element.name,
        img           : element.preview_picture && element.preview_picture.src ? element.preview_picture.src : '',
        publishedDate : element.published_at,
        rating        : element.rating,
        themes        : element.themes,

        // Флаг Пройдено
        isPassed,
        isNew    : Boolean(userData && element.id && userData[element.id] && userData[element.id].is_new),
        progress : userData && element.id && userData[element.id] ? userData[element.id].progress : null,
        cert     : userData && element.id && userData[element.id] && userData[element.id].cert ? userData[element.id].cert : null,
        stars    : {
          value : userData && element.id && userData[element.id] ? userData[element.id].stars : null,
          total : element.total_stars
        }
      };
    });
  }

  if (category.themes && category.themes.length) {
    result.themes = category.themes;
  }

  return result;
};

export const getConvertEvents = (events, userData) => {
  const result = {
    list   : [],
    themes : []
  };

  if (events.list && events.list.length) {
    result.list = events.list.map((element) => {
      const link = userData && element.id && userData[element.id] && userData[element.id].webinar_link
        ? userData[element.id].webinar_link
        : '';

      const eventResult = {
        link,
        id          : element.id,
        title       : element.name,
        description : element.preview_text,

        date            : element.date,
        durationMinutes : element.duration_minutes,

        publishedDate : element.published_at,
        rating        : element.rating,
        themes        : element.themes,
        subcategory   : element.type,
        specialist    : {
          img        : element.specialist ? element.specialist.picture : null,
          surname    : element.specialist ? element.specialist.last_name : null,
          name       : element.specialist ? element.specialist.name : null,
          middleName : element.specialist ? element.specialist.middle_name : null,
          position   : element.specialist ? element.specialist.position : null
        },
        signs: element.signs
      };

      if (element.guest_expert) {
        eventResult.guestExpert = {
          img        : element.guest_expert.picture,
          surname    : element.guest_expert.last_name,
          name       : element.guest_expert.name,
          middleName : element.guest_expert.middle_name,
          position   : element.guest_expert.position
        };
      } else {
        eventResult.guestExpert = null;
      }

      return eventResult;
    });
  }

  if (events.themes && events.themes.length) {
    result.themes = events.themes;
  }

  if (events.types && events.types.length) {
    result.subcategories = events.types;
  }

  return result;
};

// Для видео
export const getConvertVideos = (videos) => {
  const result = {
    list          : [],
    themes        : [],
    subcategories : []
  };

  if (videos.list && videos.list.length) {
    result.list = videos.list.map((element) => {
      let specialists = [];
      if (element.speakers && element.speakers.length) {
        specialists = element.speakers.map((specialist) => ({
          id         : specialist.id ? specialist.id : Math.random().toString(36).substring(7),
          img        : specialist.preview_picture && specialist.preview_picture.src ? specialist.preview_picture.src : null,
          surname    : specialist.last_name ? specialist.last_name : null,
          name       : specialist.name ? specialist.name : null,
          middleName : specialist.middle_name ? specialist.middle_name : null
        }));
      }

      return {
        id        : element.id,
        typeVideo : element.video_params && element.video_params.type ? element.video_params.type : null,
        videoId   : element.video_params && element.video_params.params && element.video_params.params.video_id
          ? element.video_params.params.video_id
          : null,
        ownerVideoId: element.video_params && element.video_params.params && element.video_params.params.owner_id
          ? element.video_params.params.owner_id
          : null,
        link          : element.link ? element.link : '',
        title         : element.name,
        isCustomBg    : element.is_custom_background,
        img           : element.preview_picture && element.preview_picture.src ? element.preview_picture.src : '',
        publishedDate : element.published_at,
        rating        : element.rating,
        views         : element.views_count,
        themes        : element.themes,
        subcategories : element.tags,
        specialists
      };
    });
  }

  if (videos.themes && videos.themes.length) {
    result.themes = videos.themes;
  }

  if (videos.tags && videos.tags.length) {
    result.subcategories = videos.tags;
  }

  return result;
};

const getFormattedUsefulMaterial = (element) => ({
  id            : element.id,
  link          : element.link,
  file          : element.file && element.file.src ? element.file.src : '',
  title         : element.name,
  description   : element.preview_text,
  img           : element.preview_picture && element.preview_picture.src ? element.preview_picture.src : '',
  publishedDate : element.published_at,
  themes        : element.themes,
  subcategories : element.subcategories
});

const getThemesUsefulMaterialForRole = (idsThemes, themes) => {
  const result = [...idsThemes];

  themes.forEach((theme) => {
    if (!result.includes(theme.id)) {
      result.push(theme.id);
    }
  });

  return result;
};

const getSubcategoriesUsefulMaterialForRole = (idsSubcategories, subcategories) => {
  const result = [...idsSubcategories];

  subcategories.forEach((subcategory) => {
    if (!result.includes(subcategory.id)) {
      result.push(subcategory.id);
    }
  });

  return result;
};

// Для полезных материалов
export const getConvertUsefulMaterials = (usefulMaterials) => {
  const result = {
    fullList: []
  };

  if (!usefulMaterials.list || !usefulMaterials.list.length) {
    return result;
  }

  result.fullList = usefulMaterials.list;

  // Перебираем список материалов
  // Разбиваю на 2 - для специалиста и для пациентов (consumer, specialist)
  usefulMaterials.list.forEach((element) => {
    element.for.forEach((role) => {
      if (!hasProperty(result, role)) {
        result[role] = {
          list             : [],
          idsThemes        : [],
          idsSubcategories : []
        };
      }

      result[role].list.push(getFormattedUsefulMaterial(element));

      // В список id фильтров добавляем темы, указанные в карточке (в зависимости от роли)
      if (element.themes && element.themes.length) {
        result[role].idsThemes = getThemesUsefulMaterialForRole(result[role].idsThemes, element.themes);
      }

      // В список id фильтров добавляем подкатегории, указанные в карточке (в зависимости от роли)
      if (element.subcategories && element.subcategories.length) {
        result[role].idsSubcategories = getSubcategoriesUsefulMaterialForRole(result[role].idsSubcategories, element.subcategories);
      }
    });
  });

  // Получаем итоговый список тем (с id и названиями) с исходной сортировкой
  if (usefulMaterials.themes && usefulMaterials.themes.length) {
    Object.keys(result).forEach((role) => {
      result[role].themes = result[role].idsThemes && result[role].idsThemes.length
        ? usefulMaterials.themes.filter((theme) => result[role].idsThemes.includes(theme.id))
        : [];
    });
  }

  // Получаем итоговый список подкатегорий (с id и названиями) с исходной сортировкой
  if (usefulMaterials.subcategories && usefulMaterials.subcategories.length) {
    Object.keys(result).forEach((role) => {
      result[role].subcategories = result[role].idsSubcategories && result[role].idsSubcategories.length
        ? usefulMaterials.subcategories.filter((subcategory) => result[role].idsSubcategories.includes(subcategory.id))
        : [];
    });
  }

  return result;
};

// Для полезных материалов - раздел
export const getConvertVideos1 = (videos) => {
  const result = {
    list          : [],
    themes        : [],
    subcategories : []
  };

  if (videos.list && videos.list.length) {
    result.list = videos.list.map((element) => {
      let specialists = [];
      if (element.speakers && element.speakers.length) {
        specialists = element.speakers.map((specialist) => ({
          id         : specialist.id ? specialist.id : Math.random().toString(36).substring(7),
          img        : specialist.preview_picture && specialist.preview_picture.src ? specialist.preview_picture.src : null,
          surname    : specialist.last_name ? specialist.last_name : null,
          name       : specialist.name ? specialist.name : null,
          middleName : specialist.middle_name ? specialist.middle_name : null
        }));
      }

      return {
        id            : element.id,
        youtubeId     : element.youtube_id,
        link          : element.link ? element.link : '',
        title         : element.name,
        isCustomBg    : element.is_custom_background,
        img           : element.preview_picture && element.preview_picture.src ? element.preview_picture.src : '',
        publishedDate : element.published_at,
        rating        : element.rating,
        views         : element.views_count,
        themes        : element.themes,
        subcategories : element.tags,
        specialists
      };
    });
  }

  if (videos.themes && videos.themes.length) {
    result.themes = videos.themes;
  }

  if (videos.tags && videos.tags.length) {
    result.subcategories = videos.tags;
  }

  return result;
};

// Для новостей
export const getConvertNews = (news) => {
  const result = {
    list: []
  };

  if (news && news.list && news.list.length) {
    result.list = news.list.map((element) => ({
      id            : element.id,
      link          : element.link,
      title         : element.name,
      description   : element.preview_text,
      img           : element.preview_picture && element.preview_picture.src ? element.preview_picture.src : '',
      publishedDate : element.published_at
    }));
  }

  return result;
};

// Преобразование данных, полученных из апи (список материалов)
// eslint-disable-next-line max-statements
export const getConvertDataMaterials = (data) => {
  if (!data || !hasProperty(data, 'materials')) {
    return {};
  }

  const result = {};

  // Статьи
  if (data && hasProperty(data.materials, 'articles')) {
    const userData = hasProperty(data, 'user_data') && data.user_data && hasProperty(data.user_data, 'articles')
      ? data.user_data.articles
      : null;
    result.articles = getConvertMaterial(data.materials.articles, userData);
  }

  // Ближайшие мероприятия
  if (data && hasProperty(data.materials, 'events')) {
    const userData = hasProperty(data, 'user_data') && data.user_data && hasProperty(data.user_data, 'events')
      ? data.user_data.events
      : null;
    result.events = getConvertEvents(data.materials.events, userData);
  }

  // Видео
  if (data && hasProperty(data.materials, 'videos')) {
    result.videos = getConvertVideos(data.materials.videos);
  }

  // Online-тренинги
  if (data && hasProperty(data.materials, 'trainings')) {
    const userData = hasProperty(data, 'user_data') && data.user_data && hasProperty(data.user_data, 'trainings')
      ? data.user_data.trainings
      : null;
    result.onlineTrainings = getConvertMaterial(data.materials.trainings, userData);
  }

  // Продукты
  if (data
    && hasProperty(data.materials, 'products')
    && data.materials.products
    && data.materials.products.list
    && data.materials.products.list.length
  ) {
    result.products = {
      list: data.materials.products.list.map((element) => getConvertDataProduct(element))
    };
  }

  // Полезные материалы
  if (data && hasProperty(data.materials, 'useful_materials')) {
    result.usefulMaterials = getConvertUsefulMaterials(data.materials.useful_materials);
  }

  // Новости Alcon
  if (data && hasProperty(data.materials, 'news')) {
    result.news = getConvertNews(data.materials.news);
  }

  return result;
};
